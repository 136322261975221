<template>
  <div class="page-content">
    <div class="container">
      <NavBtn :list="navList" />
    </div>
  </div>
</template>

<script>
import NavBtn from '@component/Module/NavBtn';

export default {
  name: 'Employee',
  components: {
    NavBtn,
  },
  data() {
    return {
      navList: [
        {
          label: 'Заявки в работе',
          name: 'ASSISTANCE_PROVIDE_COMPANY_APPLICATIONS',
          icon: require('@img/icons/app-new-provide.svg'),
        },
        {
          label: 'Отклики',
          name: 'ASSISTANCE_PROVIDE_COMPANY_RESPONSES',
          icon: require('@img/icons/app-responses-provide.svg'),
        },
        {
          label: 'Выполненные',
          name: 'ASSISTANCE_PROVIDE_COMPANY_COMPLETED_APPLICATIONS',
          icon: require('@img/icons/app-completed-provide.svg'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
